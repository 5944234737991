.bui .bui-badge.bui-badge-enter {
  animation: badgeEnter 1.44s linear;
  transform-origin: center;
}

.bui .bui-badge.bui-badge-exit {
  animation: badgeExit 0.36s ease-out;
  animation-fill-mode: forwards;
  transform-origin: center;
}

@keyframes badgeEnter {
  0% {
    transform: scale(0);
  }

  12% {
    transform: scale(1.2);
  }

  24% {
    transform: scale(0.75);
  }

  36% {
    transform: scale(1.1);
  }

  48% {
    transform: scale(0.9);
  }

  54% {
    transform: scale(1);
  }
}

@keyframes badgeExit {
  0% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0);
  }
}
