html,
body {
  font-family: 'helvetica neue', arial, sans-serif;
  margin: 0;
  padding: 0;
  background: var(--background);
  color: var(--on-background);
}

* {
  box-sizing: border-box;
}
