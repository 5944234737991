.bui input[type='date'] {
  padding: 23px 15px 0 15px;
  margin: 0;
  height: auto;
}

.bui input[type='date']::-webkit-inner-spin-button {
  display: none;
}

.bui input[type='date']::-webkit-datetime-edit-text,
.bui input[type='date']::-webkit-datetime-edit-year-field,
.bui input[type='date']::-webkit-datetime-edit-month-field,
.bui input[type='date']::-webkit-datetime-edit-day-field {
  display: none;
}

.bui input[type='date']::-webkit-calendar-picker-indicator {
  color: var(--on-surface-light);
}

.bui input[type='date']::-webkit-calendar-picker-indicator:hover {
  background-color: transparent;
  color: var(--on-surface);
  cursor: pointer;
}

.bui input[type='date']::-webkit-calendar-picker-indicator:active {
  color: var(--accent);
}

.bui.bui-showdate input[type='date']::-webkit-datetime-edit-text,
.bui.bui-showdate input[type='date']::-webkit-datetime-edit-year-field,
.bui.bui-showdate input[type='date']::-webkit-datetime-edit-month-field,
.bui.bui-showdate input[type='date']::-webkit-datetime-edit-day-field {
  display: inline-block;
}
