@import './dist/bongo-ui.css';

.bui {
  --hue: 328;
  --saturation: 100%;
  --lightness: 62%;

  --background: white;
  --on-background: hsl(var(--hue) 10% 10%);
  --on-background-light: hsl(var(--hue) calc(var(--saturation) * 0.1) 48%);

  --container-background: hsl(var(--hue), 50%, 96%);
  --container-outline: hsl(var(--hue), 25%, 82%);
  --container-outline-lighter: hsl(var(--hue), 25%, 92%);

  --autocomplete: hsl(55, calc(var(--saturation) * 0.87), 66%); /* saturation: 87% */

  --surface: white;
  --surface-alternative: hsl(var(--hue) 20% 96%);
  --surface-highlight: hsl(var(--hue) var(--saturation) var(--lightness));
  --on-surface: hsl(var(--hue) 10% 10%);
  --on-surface-light: hsl(var(--hue) 10% 48%);
  --on-surface-lighter: hsl(var(--hue) 10% 80%);

  --inverted-surface: hsl(var(--hue) 20% 30%);
  --inverted-surface-highlight: white;
  --on-inverted-surface: hsl(var(--hue) 20% 95%);

  --ok-hue: 124;
  --ok: hsl(var(--ok-hue) calc(var(--saturation) * 0.48) 40%); /* saturation: 48% */
  --ok-text: hsl(var(--ok-hue) calc(var(--saturation) * 0.48) 36%); /* saturation: 48% */
  --on-ok: white;

  --error-hue: 0;
  --error: hsl(var(--error-hue) calc(var(--saturation) * 0.9) 49%); /* saturation: 90% */
  --error-text: hsl(var(--error-hue) var(--saturation) 45%); /* saturation: 100% */
  --on-error: white;
  --error-highlight: white;

  --warning-hue: 36;
  --warning: hsl(var(--warning-hue) calc(var(--saturation) * 0.97) 58%); /* saturation: 97% */
  --warning-text: hsl(var(--warning-hue) var(--saturation) 38%); /* saturation: 100% */
  --on-warning: hsl(var(--hue) 10% 10%);

  --accent: hsl(var(--hue) var(--saturation) var(--lightness));
  --accent-text: hsl(var(--hue) var(--saturation) calc(var(--lightness) * 0.9));
  --accent-on-inverted-surface: hsl(var(--hue) var(--saturation) calc(var(--lightness) * 1.2));
  --accent-surface: var(--accent);
  --accent-light: hsl(var(--hue) var(--saturation) 93%);
  --accent-highlight: white;
  --on-accent: white;

  --selection-control: hsl(var(--hue) 10% 62%);
  --on-selection-control: #fff;

  --badge: var(--error);

  --navigation: var(--surface);
  --on-navigation-resting: var(--on-surface-light);
  --on-navigation-active: var(--accent);
  --navigation-highlight: var(--surface-highlight);

  --navigation-inverted: var(--inverted-surface);
  --navigation-inverted-highlight: white;
  --on-navigation-inverted-resting: hsl(var(--hue) 20% 75%);
  --on-navigation-inverted-active: var(--inverted-surface-highlight);

  --navigation-accent: var(--accent-surface);
  --navigation-accent-highlight: white;
  --on-navigation-accent-resting: hsl(var(--hue) 100% 85%);
  --on-navigation-accent-active: white;

  --modal-background: hsla(var(--hue), 20%, 90%, 0.9);
  --box-shadow-color: hsla(var(--hue), 20%, 33%, 0.33);

  font-family: 'helvetica neue', arial, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@media screen and (prefers-color-scheme: dark) {
  .bui {
    --background: hsl(var(--hue) 20% 13%);
    --on-background: hsl(var(--hue) 20% 95%);
    --on-background-light: hsl(var(--hue) 10% 60%);

    --container-background: hsl(var(--hue), 20%, 20%);
    --container-outline: hsl(var(--hue), 15%, 30%);
    --container-outline-lighter: hsl(var(--hue), 15%, 25%);

    --autocomplete: hsl(55, calc(var(--saturation) * 0.86), 14%); /* saturation: 86% */

    --surface: hsl(var(--hue) 20% 13%);
    --surface-alternative: hsl(var(--hue) 20% 5%);
    --surface-highlight: hsl(var(--hue) var(--saturation) 75%);

    --on-surface: hsl(var(--hue) 20% 95%);
    --on-surface-light: hsl(var(--hue) 10% 60%);
    --on-surface-lighter: hsl(var(--hue) 10% 40%);

    --inverted-surface: hsl(var(--hue) 20% 25%);
    --on-inverted-surface: hsl(var(--hue) 20% 95%);

    --ok-text: hsl(var(--ok-hue) calc(var(--saturation) * 0.38) 45%); /* saturation: 38% */
    --error: hsl(var(--error-hue) var(--saturation) 49%); /* saturation: 100% */
    --error-text: hsl(var(--error-hue) var(--saturation) 60%); /* saturation: 100% */
    --warning-text: hsl(
      var(--warning-hue) calc(var(--saturation) * 0.95) 35%
    ); /* saturation: 95% */

    --accent-surface: hsl(
      var(--hue) var(--saturation) calc(var(--lightness) * 0.935483871)
    ); /* lightness 58% */
    --accent: hsl(
      var(--hue) var(--saturation) calc(var(--lightness) * 1.1290322581)
    ); /* lightness: 70% */
    --accent-text: hsl(var(--hue) var(--saturation) calc(var(--lightness) * 1.1));
    --accent-light: hsl(
      var(--hue) var(--saturation) calc(var(--lightness) * 0.4838709677)
    ); /* lightness: 30% */

    --selection-control: hsl(var(--hue) 10% 40%);
    --on-selection-control: hsl(var(--hue) 20% 95%);

    --modal-background: hsla(var(--hue), 20%, 2%, 0.88);
    --box-shadow-color: hsla(var(--hue), 20%, 5%, 0.9);
  }
}

.bui .heading-1 {
  font-size: 6em; /* 96px */
  line-height: 1.04166666; /* 100px */
  font-weight: 300;
}

.bui .heading-2 {
  font-size: 3.75em; /* 60px */
  line-height: 1.03333; /* 62px */
  font-weight: 300;
}

.bui .heading-3 {
  font-size: 3em; /* 48px */
  line-height: 1.04166666; /* 50px */
  font-weight: normal;
}

.bui .heading-4 {
  font-size: 2.125em; /* 34px */
  line-height: 1.05888; /* 36px */
  font-weight: normal;
}

.bui .heading-5 {
  font-size: 1.5em; /* 24px */
  line-height: 1.083333; /* 26px */
  font-weight: normal;
}

.bui .heading-6 {
  font-size: 1.25em; /* 20px */
  line-height: 1.1; /* 22px */
  font-weight: 400;
}

.bui .heading-7 {
  font-size: 1em; /* 16px */
  line-height: 1.25; /* 20px */
  font-weight: 600;
}

.bui .body-1 {
  font-size: 1em; /* 16px */
  line-height: 1.25; /* 20px */
}

.bui .body-2 {
  font-size: 0.875em; /* 14px */
  line-height: 1.4285714286; /* 20px */
}

.bui .caption {
  font-size: 0.75em; /* 12px */
  line-height: 1.6666666667; /* 20px */
}

.bui .overline {
  font-size: 0.625em; /* 10px */
  line-height: 1; /* 10px */
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.bui-show-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
  background-color: var(--surface-alternative);
}

.bui-show-scroll::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: hsl(var(--hue) calc(var(--saturation) * 1) calc(var(--lightness) * 1.25));
}

@media screen and (prefers-color-scheme: dark) {
  .bui-show-scroll::-webkit-scrollbar-thumb {
    background-color: hsl(var(--hue) calc(var(--saturation) * 1) calc(var(--lightness) * 0.6));
  }
}

.bui-show-scroll-square::-webkit-scrollbar-thumb {
  border-radius: 0;
}

.bui-show-scroll::-webkit-scrollbar-corner {
  background: var(--surface);
}

.bui-show-keyboard-focus:focus-visible {
  outline-offset: 3px;
  outline: 3px solid var(--accent);
}

.bui-show-keyboard-focus-inside:focus-visible {
  outline-offset: -3px;
  outline: 3px solid var(--accent);
}

.bui a,
.bui a:visited {
  color: var(--accent-text);
  -webkit-tap-highlight-color: hsla(var(--hue), var(--saturation), 62%, 0.1);
}

.bui a:focus {
  outline-offset: 3px;
  outline: 3px solid var(--accent);
}

.bui a.discrete,
.bui a.discrete:visited {
  color: var(--on-surface-light);
}

.bui input {
  font-family: 'helvetica neue', arial, sans-serif;
  -webkit-appearance: none;
}

.bui::-moz-focus-inner {
  border: 0;
}

.bui textarea {
  font-family: 'helvetica neue', arial, sans-serif;
}

.bui input[type='search'] {
  height: 54px;
}

.bui input[type='search']::-webkit-search-decoration,
.bui input[type='search']::-webkit-search-cancel-button,
.bui input[type='search']::-webkit-search-results-button,
.bui input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.bui input:-webkit-autofill {
  border: none;
  -webkit-text-fill-color: var(--on-surface);
  -webkit-box-shadow: 0 0 0px 1000px var(--autocomplete) inset;
  box-shadow: 0 0 0px 1000px var(--autocomplete) inset;
}

.bui input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
