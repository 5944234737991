.bui .spinner {
  animation: rotator 1.44s linear infinite;
  transform-origin: center;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

/*  Circumferens ~126 at radius 20 */
.bui .loading {
  stroke-dasharray: 112;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.44s cubic-bezier(0.25, 0, 0.36, 1) infinite;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 112;
  }
  50% {
    stroke-dashoffset: 26;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 112;
    transform: rotate(450deg);
  }
}
